<template>
  <div class="container" v-if="initOk">
    <van-divider
      v-if="id == undefined"
      :style="{
        borderColor: '#ee0a24',
        padding: '0 100px',
      }"
    >
      注意事项
    </van-divider>

    <van-notice-bar
      v-if="id == undefined"
      wrapable
      :scrollable="false"
      text="广西外国语学院承诺不会让任何一个学生因为经济困难失学，如有疑问请联系0771-4730985，谢谢！"
    />

    <van-notice-bar
      v-if="id && status == 'PENDING'"
      left-icon="volume-o"
      wrapable
      :scrollable="false"
      text="您的申请我们已经收到，预计24小时内审核完毕；申请批准后，应缴费用金额将会自动扣减，请您在应缴费用扣减后办理下一步手续，谢谢！"
    />

    <van-notice-bar
      v-if="id && status == 'SUCCEED'"
      left-icon="volume-o"
      wrapable
      :scrollable="false"
      text="您的申请已通过审核"
    />

    <van-notice-bar
      v-if="id && status == 'FAILED'"
      left-icon="volume-o"
      wrapable
      :scrollable="false"
      :text="approve"
    />

    <van-form @submit="onSubmit">
      <div>
        <p class="input-info">是否办理了《生源地信用助学贷款》</p>
        <van-field
          :disabled="id == undefined ? false : true"
          readonly
          clickable
          name="isLoansStr"
          :value="isLoansStr"
          placeholder="请选择"
          @click="showIsLoansPicker = true"
          :rules="[
            {
              required: true,
              message: '请选择是否办理了《生源地信用助学贷款》',
            },
          ]"
        />
        <van-popup
          v-if="id == undefined"
          v-model="showIsLoansPicker"
          position="bottom"
        >
          <van-picker
            show-toolbar
            :columns="isLoansStrColumns"
            @confirm="onIsLoansConfirm"
            @cancel="showIsLoansPicker = false"
          />
        </van-popup>
        <p class="input-info">申请金额</p>
        <!-- <van-field
          :disabled="id == undefined ? false : true"
          v-model="fee"
          name="fee"
          type="number"
          placeholder="请输入"
          :rules="[{ required: true, message: '请输入您需要申请的金额' }]"
        /> -->
        <van-field
          :disabled="id == undefined ? false : true"
          v-model="amount"
          label="学杂费总金额"
          name="amount"
          type="number"
          input-align="right"
          readonly
        >
          <template #extra><span style="padding: 0 5px">元</span></template>
        </van-field>
        <van-field
          :disabled="id == undefined ? false : true"
          v-model="amountOfLoan"
          label="申请国家助学贷款金额"
          name="amountOfLoan"
          type="number"
          placeholder="请输入国家助学贷款金额"
          :rules="[{ required: true }]"
          input-align="right"
          @input="amountOfLoanInput"
          :readonly="isLoansStr == '否'"
          label-width="150"
        >
          <template #extra><span style="padding: 0 5px">元</span></template>
        </van-field>
        <van-field
          :disabled="id == undefined ? false : true"
          v-model="amountOfWillPay"
          label="预缴金额"
          name="amountOfWillPay"
          type="number"
          placeholder="请输入您想预缴的金额"
          :rules="[{ required: true }]"
          input-align="right"
          @input="amountOfWillPayInput"
        >
          <template #extra><span style="padding: 0 5px">元</span></template>
        </van-field>
        <van-field
          :disabled="id == undefined ? false : true"
          v-model="fee"
          label="缓交金额"
          name="fee"
          type="number"
          placeholder="请输入您需要缓交的金额"
          :rules="[{ required: true }]"
          input-align="right"
          readonly
        >
          <template #extra><span style="padding: 0 5px">元</span></template>
        </van-field>
        <p class="input-info">申请原因</p>
        <van-field
          name="reasons"
          :rules="[{ required: true, message: '请选择申请原因' }]"
        >
          <template #input>
            <van-checkbox-group
              v-model="reasons"
              :disabled="id == undefined ? false : true"
            >
              <van-checkbox name="1" shape="square"
                >1：办理了国家生源地信用助学贷款后存在交费困难</van-checkbox
              >
              <van-checkbox name="2" shape="square"
                >2：原建档立卡贫困户家庭学生</van-checkbox
              >
              <van-checkbox name="3" shape="square"
                >3：最低生活保障家庭学生</van-checkbox
              >
              <van-checkbox name="4" shape="square"
                >4：特困供养学生</van-checkbox
              >
              <van-checkbox name="5" shape="square"
                >5：烈士家庭子女</van-checkbox
              >
              <van-checkbox name="6" shape="square"
                >6：家庭经济困难残疾学生或残疾人子女</van-checkbox
              >
              <van-checkbox name="7" shape="square"
                >7：家庭主要成员患有重大疾病</van-checkbox
              >
              <van-checkbox name="8" shape="square"
                >8：家庭主要收入创造者因故丧失劳动能力</van-checkbox
              >
              <van-checkbox name="9" shape="square"
                >9：遭到自然灾害，造成重大损失，无力负担家庭学生</van-checkbox
              >
              <van-checkbox name="10" shape="square"
                >10：单亲困难家庭</van-checkbox
              >
              <van-checkbox name="11" shape="square">11：孤儿家庭</van-checkbox>
              <van-checkbox name="12" shape="square"
                >12：家庭存在暂时的经济困难</van-checkbox
              >
            </van-checkbox-group>
          </template>
        </van-field>

        <p class="input-info">缓交学费期限</p>
        <van-field
          :disabled="id == undefined ? false : true"
          readonly
          clickable
          name="refund"
          :value="refund"
          placeholder="请选择"
          @click="showRefundPicker = true"
          :rules="[{ required: true, message: '请选择缓交学费期限' }]"
        />
        <van-popup
          v-if="id == undefined"
          v-model="showRefundPicker"
          position="bottom"
        >
          <van-picker
            show-toolbar
            :columns="refundColumns"
            @confirm="onRefundConfirm"
            @cancel="showRefundPicker = false"
          />
        </van-popup>
        <p class="input-info">证明材料(贷款受理证明、帮扶手册等贫困证明材料)</p>
        <van-field
          name="uploader"
          :rules="[{ required: true, message: '请选择照片' }]"
        >
          <template #input>
            <van-uploader
              :disabled="id == undefined ? false : true"
              :deletable="id == undefined ? true : false"
              v-model="uploader"
              :after-read="afterRead"
              multiple
              :max-count="3"
              :max-size="5 * 1024 * 1024"
              @oversize="onOversize"
            />
          </template>
        </van-field>
        <div class="spec-tip">
          请点击
          <a href="/关于缓交学费申请的说明.doc" target="_blank">此处下载</a>
          、打印、正确填写
          <a href="/关于缓交学费申请的说明.doc" target="_blank">
            《关于缓交学费申请的说明》
          </a>
          文件，并在签名处手写签字后拍照上传。
        </div>
        <p class="input-info">缓交原因</p>
        <van-field
          :disabled="id == undefined ? false : true"
          name="memo"
          v-model="memo"
          rows="2"
          autosize
          type="textarea"
          placeholder="请输入缓交原因"
        />
        <div v-if="id == undefined" style="margin: 16px">
          <van-button round block type="danger" native-type="submit"
            >提交申请</van-button
          >
        </div>
        <div v-if="id && status == 'PENDING'" style="margin: 16px">
          <van-button
            round
            block
            type="default"
            native-type="button"
            @click="cancel()"
            >取消申请</van-button
          >
        </div>
        <!-- <div v-else-if="id && status == 'SUCCEED'" style="margin: 16px">
          <van-button
            round
            block
            type="default"
            native-type="button"
            @click="cancel()"
            >取消申请</van-button
          >
        </div> -->
        <div v-else-if="id && status == 'FAILED'" style="margin: 16px">
          <van-button
            round
            block
            type="default"
            native-type="button"
            @click="cancel()"
            >重新申请</van-button
          >
        </div>
      </div>
    </van-form>
    <br />
  </div>
</template>
<script>
import { Toast } from "vant";
import { Notify } from "vant";
import { Dialog } from "vant";
import * as api from "@/apis/api";
export default {
  data() {
    return {
      initOk: false,
      id: undefined,
      status: undefined,
      isLoansStr: "",
      isLoansStrColumns: ["是", "否"],
      showIsLoansPicker: false,
      fee: 0,
      reasons: undefined,
      refund: undefined,
      refundColumns: ["1个月", "2个月", "3个月"],
      showRefundPicker: false,
      memo: undefined,
      approve: undefined,
      uploader: [],
      amount: 0,
      amountOfLoan: undefined,
      amountOfWillPay: undefined,
    };
  },
  created() {
    this.findAmount();
  },
  methods: {
    findAmount() {
      api.fee().then((res) => {
        this.amount = res.amount;

        this.findFeeReq();
      });
    },
    onIsLoansConfirm(value) {
      this.isLoansStr = value;
      this.showIsLoansPicker = false;
      console.log(value);
      if (this.isLoansStr == "否") {
        this.amountOfLoan = 0;
      } else {
        this.amountOfLoan = undefined;
      }
    },
    onRefundConfirm(value) {
      this.refund = value;
      this.showRefundPicker = false;
    },
    //上传
    onOversize(file) {
      console.log(file);
      Toast.fail("文件大小不能超过5MB");
    },
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData();
      formData.append("file", file.file);
      api.commonUpload(formData).then((res) => {
        if (res.state == "ok") {
          file.status = "succeed";
          file.message = "上传成功";
          file.url = res.msg;
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    //上传END
    findFeeReq() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      api.feeReq().then((res) => {
        if (res.id) {
          this.id = res.id;
          this.status = res.status;
          this.isLoansStr = res.isLoans == true ? "是" : "否";
          this.fee = res.fee;
          //贷款金额
          this.amountOfLoan = res.amountOfLoan;
          //预缴金额
          this.amountOfWillPay = this.amount - this.amountOfLoan - this.fee;
          this.reasons = JSON.parse(res.reason);
          this.refund = res.refund;
          this.uploader = [];
          if (res.pics) {
            JSON.parse(res.pics).forEach((r) => {
              this.uploader.push({ url: r });
            });
          }
          this.memo = res.memo;
          this.approve = res.approve;
        } else {
          this.id = undefined;
        }
        Toast.clear();
        this.initOk = true;
      });
    },
    onSubmit(values) {
      api.userProfile().then((res) => {
        console.log(res);
        if (!res.orgFullPathName.startsWith("专科")) {
          if (this.amountOfWillPay < this.amount * 0.1) {
            Toast.fail(
              "预缴金额不能小于总学杂费的10%，即：" + this.amount * 0.1 + "元"
            );
            this.amountOfWillPay = undefined;
            return;
          }
        }
        values.reason = JSON.stringify(values.reasons);
        values.isLoans = this.isLoansStr == "是" ? true : false;
        //贷款金额
        values.amountOfLoan = this.amountOfLoan;
        let pics = [];
        this.uploader.forEach((u) => {
          if (u && u != null && u.url && u.url != null) {
            pics.push(u.url);
          }
        });
        if (pics.length < 1) {
          Notify({ type: "danger", message: "请上传证明材料" });
          return;
        }
        values.uploader = [];
        values.pics = JSON.stringify(pics);
        api.insertFeeReq(values).then((res) => {
          if (res.state == "ok") {
            Toast.success("提交成功，请等待审核");
            // this.findFeeReq();
            this.$router.push({ path: "/checkin", query: {} });
          } else {
            Notify({ type: "danger", message: res.msg });
          }
        });
        console.log("submit", values);
      });
    },
    cancel() {
      Dialog.confirm({
        title: "提示",
        message: "您确认取消吗？",
      })
        .then(() => {
          api.cancelFeeReq().then((res) => {
            if (res.state == "ok") {
              this.findFeeReq();
              Toast.success("成功取消");
            } else {
              Notify({ type: "danger", message: res.msg });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    amountOfLoanInput(val) {
      val = Number(val);
      if (val < 0) {
        Toast.fail("已贷款金额不能小于0");
        this.amountOfLoan = undefined;
        return;
      }
      if (val > this.amount) {
        Toast.fail("已贷款金额不能大于" + this.amount);
        this.amountOfLoan = undefined;
        return;
      }
      if (this.amountOfWillPay == undefined) {
        this.fee = this.amount - val;
      } else {
        this.fee = this.amount - this.amountOfWillPay - val;
      }
      if (this.fee < 0) {
        Toast.fail("已贷款金额不正确");
        this.amountOfWillPay = undefined;
      }
    },
    amountOfWillPayInput(val) {
      val = Number(val);
      if (val < 0) {
        Toast.fail("预缴金额不能小于0");
        this.amountOfWillPay = undefined;
        return;
      }
      if (val > this.amount) {
        Toast.fail("预缴金额不能大于" + this.amount);
        this.amountOfWillPay = undefined;
        return;
      }
      if (this.amountOfLoan == undefined) {
        this.fee = this.amount - val;
      } else {
        this.fee = this.amount - this.amountOfLoan - val;
      }
      if (this.fee < 0) {
        Toast.fail("预缴金额不正确");
        this.amountOfWillPay = undefined;
      }
    },
  },
};
</script>
<style scoped>
.container {
  padding: 0px 0px 10px 0px;
}
.input-info {
  padding: 10px;
  margin: 0;
  font-size: 14px;
}
.van-checkbox {
  margin-bottom: 10px;
  font-size: 14px;
}
.van-checkbox:last-child {
  margin-bottom: 0px;
}
.spec-tip {
  padding: 10px;
  color: #ed6a0c;
  background-color: #fffbe8;
  font-size: 14px;
  line-height: 24px;
}
.spec-tip a {
  color: red;
}
.req-money {
  padding: 10px;
  display: flex;
  line-height: 30px;
}
</style>
